import React, { useEffect, useState } from 'react';

import classes from 'src/components/NewRvPanelFeatureBar/style.module.scss';
import { BANIB_INFO_LINK, FEATURE_BAR_REDIRECT_LINK } from 'src/config';
import bannerClient from 'src/images/banner-client.png';
import bannerBnib from 'src/images/banner-banib.png';

const banners = [
  { img: bannerClient, url: FEATURE_BAR_REDIRECT_LINK },
  { img: bannerBnib, url: BANIB_INFO_LINK },
];

const NewRvPanelFeatureBar = () => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    if (banners.length > 1) {
      const interval = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setCurrentBanner(prevBanner => (prevBanner + 1) % banners.length);
          setFade(true);
        }, 300);
      }, 45000);

      return () => clearInterval(interval);
    }
  }, []);

  const handleRedirectToApp = event => {
    event.preventDefault();
    window.open(banners[currentBanner].url, '_blank');
  };

  return (
    <div>
      <img
        onClick={handleRedirectToApp}
        className={`${classes.imageFull} ${banners.length > 1 ? (fade ? classes.fadeIn : classes.fadeOut) : ''}`}
        src={banners[currentBanner].img}
        alt="Grupo RV"
      />
    </div>
  );
};

export default NewRvPanelFeatureBar;
