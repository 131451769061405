import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Route, Switch, withRouter } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from 'typescript-cookie';

import classes from 'src/ClientApp.module.scss';
import AcceptedTermsModal from 'src/components/AcceptedTermsModal';
import ClientMenu from 'src/components/ClientMenu/ClientMenu';
import FeatureModal from 'src/components/FeatureModal';
import NewRvPanelFeatureBar from 'src/components/NewRvPanelFeatureBar';
import UserDashboardsWrapper from 'src/components/UserDashboardsWrapper';
import { CLIENT_FORM_URL, CLIENT_FORM_TITLE } from 'src/config';
import ClientFinancialSummary from 'src/containers/ClientFinancialSummary/ClientFinancialSummary';
import ClientNewOrder from 'src/containers/ClientNewOrder/ClientNewOrder';
import ClientOrders from 'src/containers/ClientOrders/ClientOrders';
import ApiContext from 'src/contexts/ApiContext';
import UserContext from 'src/contexts/UserContext';
import Header from 'src/Header';
import ClientFormSuccess from 'src/orderForm/ClientOrderForm/ClientFormSuccess';

class ClientApp extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      apiStatus: 'IDLE',
      showModal: false,
    };
  }

  /*NPS modal
  async componentDidMount() {
    const me = this.context;

    const key = `customForm_${me?.identifier}_${me?.profile?.username}`;
    const displayedPopUp = getCookie(key);

    if (!displayedPopUp) {
      setCookie(key, true, { expires: 2 });

      this.setState({ showModal: true });
    }
  }
    */

  handleCloseModal = () => this.setState({ showModal: false });

  render() {
    const me = this.context;
    const { apiStatus, showModal } = this.state;
    const { balance, contracts, features, location, userName } = this.props;

    let featureModal = null;

    const featureBar = (
      <Grid container justify="center" alignItems="flex-start" className={classes.NotificationContainer}>
        <Grid item lg={10} md={12}>
          <NewRvPanelFeatureBar />
        </Grid>
      </Grid>
    );

    const isInDashboardTab = location.pathname.includes('dashboards');

    if (features.includes('VISTORIA_VISITA_DIGITAL')) {
      featureModal = contracts.pending.length === 0 ? <FeatureModal feature="visitaDigital" /> : null;
    }

    const clientFormUrlWithIdentifier = CLIENT_FORM_URL.replace('{clientId}', me?.identifier);

    return (
      <div className={classes.ClientApp}>
        <ApiContext.Provider value={{ status: apiStatus }}>
          {featureModal}

          <AcceptedTermsModal contracts={contracts} />

          <Header userName={userName} balance={balance} features={features} client />

          <ClientMenu />

          {featureBar}

          <Grid container justify="center" alignItems="flex-start">
            <Grid item xs={12} lg={10} md={12} className={classes.Item}>
              <div className={classes.Container} style={{ paddingTop: isInDashboardTab ? 0 : '20px' }}>
                <Switch>
                  <Route exact path="/orders/dashboards" component={UserDashboardsWrapper} />

                  <Route exact path="/orders" render={() => <ClientOrders component="orders-list" />} />

                  <Route exact path="/orders/new" render={() => <ClientNewOrder features={features} />} />

                  <Route exact path="/orders/success" component={ClientFormSuccess} />

                  <Route exact path="/orders/financial-extract" component={ClientFinancialSummary} />

                  <Route
                    exact
                    path="/orders/extra-actions"
                    render={() => <ClientOrders component="order-extra-actions" />}
                  />

                  <Route exact path="/orders/checklist" render={() => <ClientOrders component="checklist-form" />} />

                  <Route
                    exact
                    path="/orders/:orderId/repair-budget"
                    render={() => <ClientOrders component="repair-budget-form" />}
                  />

                  <Route exact path="/orders/:orderId" render={() => <ClientOrders component="order-details" />} />

                  <Route exact path="/orders/:orderId/edit" render={() => <ClientOrders component="order-edit" />} />
                </Switch>
              </div>
            </Grid>
          </Grid>

          <Dialog open={showModal} maxWidth="sm" onClose={this.handleCloseModal}>
            <button className={classes.clientDashboardPopup} type="button" onClick={this.handleClickDashboardPopup}>
              <div>
                <iframe
                  width="720px"
                  height="720px"
                  title={CLIENT_FORM_TITLE}
                  style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
                  src={clientFormUrlWithIdentifier}
                  frameBorder="0"
                  marginWidth="0"
                  marginHeight="0"
                  allowFullScreen
                />
              </div>
            </button>
          </Dialog>
        </ApiContext.Provider>
      </div>
    );
  }
}

ClientApp.propTypes = {
  balance: PropTypes.string.isRequired,
  contracts: PropTypes.object.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  userName: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(ClientApp));
